import React, { useState, useRef, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { useOnClickOutside } from './hooks';
import { GlobalStyles } from './global';
import { theme } from './theme';
import { Burger, Menu } from './components';

import { AppContext } from './Helper/Context';

import './app.css';

import FocusLock from 'react-focus-lock';
import Finance from './pages/Finance/Finance';
import Ecommerce from './pages/Ecommerce/Ecommerce';
import Flows from './pages/Flows/Flows';
import Okta from './pages/Okta/Okta';
import Landing from './pages/Landing/Landing';
import Editor from './pages/Editor/Editor';

import Profile from './pages/Profile/Profile';
import General from './views/Main/General';
import Account from './views/Second/Account';
import Token from './views/Token/Token';

import Tenant from './pages/Tenant/Tenant';
import Settings from './pages/Settings/Settings';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useLocation,
  Navigate,
} from 'react-router-dom';

import CookieManager from './helpers/CookieManager';
import SessionController from './controllers/session';
import localforage from 'localforage';

import { AiOutlineConsoleSql } from 'react-icons/ai';

function App() {
  const [open, setOpen] = useState(false);
  let [text, setText] = useState('');
  const node = useRef();
  const menuId = 'main-menu';
  let [websiteUrl, setWebsiteURL] = useState(
    localStorage['current_website_url'] || 'https://'
  );
  let [imagesUrl, setImagesUrl] = useState('');
  let [logosUrl, setLogosUrl] = useState('');
  let [cvc, setCvc] = useState('');
  let [expiry, setExpiry] = useState('');
  let [focus, setFocus] = useState('');
  let [name, setName] = useState('');
  let [number, setNumber] = useState('');
  let [loadingUl, setLoadingUl] = useState(false);
  let [currentFixtures, setCurrentFixtures] = useState({
    currentLogo: '',
    currentImage: '',
  });

  let [screenShotUrl, setScreenshotUrl] = useState('');
  let [universalLoginImage, setUniversalLoginImage] = useState('');
  let [socialConnections, setSocialConnections] = useState({});
  let [mfaOptions, setMfaOptions] = useState({});

  let cookieManager = new CookieManager();

  let [tenantCookieExists, setTenantCookieExists] = useState(false);

  useOnClickOutside(node, () => setOpen(false));
  useEffect(() => {
    (async () => {
      setTenantCookieExists(await SessionController.tenantCookieExists());
      setScreenshotUrl(await localforage.getItem('editor_background') || "");
      setUniversalLoginImage(
        await localforage.getItem('universal_login_image' || "")
      );
    })();
    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);
  const handleMouseMove = (e) => {
    if (e.clientX > 200) {
      setOpen(false);
    }
  };

  return (
    <Router>
      <AppContext.Provider
        value={{
          cvc,
          setCvc,
          expiry,
          setExpiry,
          focus,
          setFocus,
          name,
          setName,
          number,
          setNumber,
          currentFixtures,
          setCurrentFixtures,
          text,
          setText,
          websiteUrl,
          setWebsiteURL,
          imagesUrl,
          setImagesUrl,
          logosUrl,
          setLogosUrl,
          screenShotUrl,
          setScreenshotUrl,
          universalLoginImage,
          setUniversalLoginImage,
          socialConnections,
          setSocialConnections,
          mfaOptions,
          setMfaOptions,
          loadingUl,
          setLoadingUl,
        }}
      >
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          {tenantCookieExists && (
            <div ref={node} id="menu-div-for-routes">
              <FocusLock disabled={!open}>
                <Burger
                  open={open}
                  setOpen={setOpen}
                  aria-controls={menuId}
                  id="styled-burger"
                />
                <Menu open={open} setOpen={setOpen} id={menuId} />
              </FocusLock>
            </div>
          )}
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/finance" element={<Finance />} />
            <Route path="/ecommerce" element={<Ecommerce />} />
            <Route path="/editor" element={<Editor />} />
            <Route path="/flows" element={<Flows />} />
            <Route path="/okta" element={<Okta />} />
            <Route path="/profile" element={<Profile />}>
              <Route path="general" element={<General />} />
              <Route path="account" element={<Account />} />
              <Route path="token" element={<Token />} />
            </Route>
            <Route path="/tenant" element={<Tenant />} />
            <Route path="/settings" element={<Settings />} />
          </Routes>
        </ThemeProvider>
      </AppContext.Provider>
    </Router>
  );
}

export default App;
