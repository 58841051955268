import React, { useState, useEffect, useContext, useRef } from 'react';
import './BrandSettings.css';
import AliceCarousel from 'react-alice-carousel';
import ImageSlider from './Carousel/Carousel';
import SettingsCards from './Cards/SettingsCards';
import axios from 'axios';
import { AppContext } from '../../../Helper/Context';
import auth0 from 'auth0-js';
import CookieController from '../../../controllers/cookie';
import BrandController from '../../../controllers/brand';
import ConfigurationController from '../../../controllers/configuration';
import localforage from 'localforage';

function BrandSettings(props) {
  const {
    websiteUrl,
    setWebsiteURL,
    imagesUrl,
    setImagesUrl,
    logosUrl,
    setLogosUrl,
    screenShotUrl,
    setScreenshotUrl,
    universalLoginImage,
    setUniversalLoginImage,
    mfaOptions,
    setMfaOptions,
    socialConnections,
    setSocialConnections,
    currentFixtures,
    setCurrentFixtures,
    loadingUl,
    setLoadingUl,
  } = useContext(AppContext);
  let [logoSearch, setLogoSearch] = useState(localStorage['logo_search'] || '');
  let [backgroundSearch, setBackgroundSearch] = useState(
    localStorage['background_search'] || ''
  );

  let [brandingCategory, setBrandingCategory] = useState(true);
  let [configurationCategory, setConfigurationCategory] = useState(false);
  let [managementCategory, setManagementCategory] = useState(false);

  let [displayAsButtonEnabled, setDisplayAsButtonEnabled] = useState(false);
  let [entConnectionEnabled, setEntConnectionEnabled] = useState(false);
  let [organizationEnabled, setOrganizationEnabled] = useState(false);

  let { domain, defaultClientId, tenant } = CookieController.getAllCookies();

  const [selectedSocial, setSelectedSocial] = useState({
    facebook: false,
    apple: false,
    'google-oauth2': false,
    linkedin: false,
    windowslive: false,
  });

  // const [facebook, setFacebook] = useState(true);
  const [selectedMFA, setSelectedMFA] = useState({
    sms: false,
    email: false,
    'push-notification': false,
    duo: false,
    'webauthn-roaming': false,
    'recovery-code': false,
    'webauthn-platform': false,
    otp: false,
  });

  const [selectedAuthenticationProfile, setSelectedAuthenticationProfile] =
    useState({
      identifier_first: false,
      webauthn_platform_first_factor: false,
      universal_login_experience: 'new',
    });

  const getCurrentMFA = async () => {
    let response = await ConfigurationController.getCurrentMFA(tenant);
    setSelectedMFA(response);
  };

  const getCurrentSocial = async () => {
    let response = await ConfigurationController.getCurrentSocial(tenant);
    setSelectedSocial(response);
  };

  const getAuthenticationProfile = async () => {
    let response = await ConfigurationController.getAuthenticationProfile(
      tenant
    );
    setSelectedAuthenticationProfile(response);
  };
  const getOrganizationPrompt = async () => {
    let response = await ConfigurationController.getOrganizationPrompt(tenant);
    setOrganizationEnabled(response);
  };
  const getCurrentEnterprise = async () => {
    let response = await ConfigurationController.getCurrentEnterprise(tenant);
    let { show_as_button, enabled } = response;
    setEntConnectionEnabled(enabled);
    setDisplayAsButtonEnabled(show_as_button);
  };

  const setMFA = async () => {
    await ConfigurationController.setMFA(tenant, selectedMFA);
  };
  const setSocial = async () => {
    await ConfigurationController.setSocial(tenant, selectedSocial);
  };
  const setAuthenticationProfile = async () => {
    await ConfigurationController.setAuthenticationProfile(
      tenant,
      selectedAuthenticationProfile
    );
  };
  const setOrganizationPrompt = async () => {
    await ConfigurationController.setOrganizationPrompt(
      tenant,
      organizationEnabled
    );
  };
  const setCurrentEnterprise = async () => {
    await ConfigurationController.setCurrentEnterprise(
      tenant,
      entConnectionEnabled,
      displayAsButtonEnabled
    );
  };

  useEffect(() => {
    (async () => {
      await getCurrentSocial();
      await getCurrentMFA();
      await getAuthenticationProfile();
      await getOrganizationPrompt();
      await getCurrentEnterprise();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (websiteUrl && websiteUrl !== 'https://') {
        let brandObj = await getBrand(websiteUrl);

        // setLoadingUl(true);
        let firstBgImage =
          localStorage['bg_url'] || brandObj.backgroundImages[0].contentUrl;
        let firstLgImage =
          localStorage['logo_url'] || brandObj.logoImages[0].contentUrl;
        brandObj.bgImage = firstBgImage;
        brandObj.lgImage = firstLgImage;

        setCurrentFixtures((prevState) => ({
          currentLogo: firstLgImage,
          currentImage: firstBgImage,
        }));
      }
    })();
    // setUniversalLoginImage('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT7QvvLqXm_8x3xJPw3j_Ns1cEBLYA5e73MKQ&usqp=CAU');
    // setScreenShotUrl('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT7QvvLqXm_8x3xJPw3j_Ns1cEBLYA5e73MKQ&usqp=CAU');
  }, []);
  useEffect(() => {
    (async () => {
      setLoadingUl(true);
      if (currentFixtures.currentLogo && currentFixtures.currentImage) {
        await setBrand();
      }
      setLoadingUl(false);
    })();
  }, [currentFixtures]);

  const webAuth = new auth0.WebAuth({
    domain,
    clientID: defaultClientId,
  });

  const initalize = async (e) => {
    if (e.key == 'Enter' || e.id === 'brandSettingURLInput') {
      // setLoadingUl(true);
      let inputUrl = e.target?.value || e.value;
      setWebsiteURL(inputUrl);
      localStorage['current_website_url'] = inputUrl;
      setScreenShot(inputUrl, setScreenshotUrl);
      let brandObj = await getBrand(inputUrl);
      // update brand

      let firstBgImage = brandObj.backgroundImages[0].contentUrl;
      let firstLgImage = brandObj.logoImages[0].contentUrl;
      brandObj.bgImage = firstBgImage;
      brandObj.lgImage = firstLgImage;

      setLogoSearch(localStorage['logo_search']);
      setBackgroundSearch(localStorage['background_search']);
      localStorage['logo_url'] = firstLgImage;
      localStorage['bg_url'] = firstBgImage;
      setCurrentFixtures((prevState) => ({
        currentLogo: firstLgImage,
        currentImage: firstBgImage,
      }));
    }
  };

  const setScreenShot = async (inputUrl, setImage) => {
    try {
      let imageRes = await BrandController.getImageFromThumioFor(inputUrl);
      let fileReader = new FileReader();
      fileReader.onload = async (file) => {
        let imageData = file.target.result;
        await localforage.setItem('editor_background', imageData);
        setImage(imageData);
        setScreenshotUrl(imageData);
      };
      fileReader.readAsDataURL(imageRes.data);
    } catch (e) {
      console.log(`Error with setting screenshot:`, e);
    }
  };

  const setUL = async (inputUrl) => {
    // console.log('Setting the UL');
    try {
      let imageRes = await BrandController.getImageFromThumioFor(inputUrl);
      let fileReader = new FileReader();
      fileReader.onload = async (file) => {
        try {
          let imageData = file.target.result;
          await localforage.setItem('universal_login_image', imageData);
          setUniversalLoginImage(imageData);
        } catch (e) {
          console.log('error setting this', e);
        }
      };
      fileReader.readAsDataURL(imageRes.data);
    } catch (e) {
      console.log('Error setting the universal login', e);
    }

    setLoadingUl(false);
  };

  const getBrand = async (inputUrl) => {
    // console.log('MgetBrandgetBrandgetBrandAIN: ');
    const pattern = /^https?:\/\/(?:www\.)?([^/]+)/;
    const match = inputUrl.match(pattern);
    let mainDomain = '';
    if (match) {
      // Extract the domain name from the first capturing group.
      const domain = match[1];
      // Split the domain name into parts.
      const parts = domain.split('.');
      // Extract the main domain (second to last part).
      mainDomain = parts[parts.length - 2];
      setLogoSearch(mainDomain + ' logo');
      localStorage['logo_search'] = mainDomain + ' logo';
      setBackgroundSearch(mainDomain);
      localStorage['background_search'] = mainDomain;
      let backgroundImages = await searchCustomBackgroundImages(
        mainDomain,
        true
      );
      let logoImages = await searchLogoImages(mainDomain, true);
      return { backgroundImages, logoImages };
    }
  };
  const updateSettings = async (e) => {
    e.target.disabled = true;
    setLoadingUl(true);
    await setAuthenticationProfile();
    await setSocial();
    await setMFA();
    await setOrganizationPrompt();
    await setCurrentEnterprise();

    let ulUrl = webAuth.client.buildAuthorizeUrl({
      clientID: defaultClientId,
      responseType: 'token id_token',
      redirectUri: `${window.location.origin}/profile`,
      nonce: (Math.random() + 1).toString(30).substring(2),
    });
    await setUL(ulUrl);
    e.target.disabled = false;
    setLoadingUl(false);
  };
  const setBrand = async () => {
    let brand = {
      logo_url: currentFixtures.currentLogo,
      background_image_url: currentFixtures.currentImage,
    };
    await BrandController.setBrand(tenant, brand);

    let ulUrl = webAuth.client.buildAuthorizeUrl({
      clientID: defaultClientId,
      responseType: 'token id_token',
      redirectUri: `${window.location.origin}/profile`,
      nonce: (Math.random() + 1).toString(30).substring(2),
    });

    await setUL(ulUrl);
  };

  const searchCustomBackgroundImages = async (e, urlEntered) => {
    if (e.key == 'Enter' || urlEntered) {
      let finalSearch = '';
      if (e.key) {
        finalSearch = e.target.value + ' Background Image';
      } else {
        finalSearch = e + '  Background Image';
      }
      // console.log('FINAL SEARCH', finalSearch);
      let backgroundImages = await axios
        .get(
          `https://api.bing.microsoft.com/v7.0/images/search?q=${finalSearch}`,
          {
            headers: {
              'Ocp-Apim-Subscription-Key': '33370f7ecda54460991c5ec01d911e3a',
            },
          }
        )
        .then((res) => {
          // console.log('RES.DATA: ', res.data);

          updateStateImages(res.data.value, true);
          return res.data.value;
        })
        .catch((err) => console.log('Error:', err));
      return backgroundImages;
    }
  };
  const updateStateImages = (data, backgroundImageBoolean) => {
    // console.log('DATA: ', data);
    let images = data.map((item) => {
      return item.contentUrl;
    });
    // console.log("IMAGES: ", images);
    if (backgroundImageBoolean) {
      setImagesUrl(images);
    } else {
      setLogosUrl(images);
    }
  };
  const searchLogoImages = async (e, urlEntered) => {
    if (e.key == 'Enter' || urlEntered) {
      let finalSearch = '';
      if (e.key) {
        finalSearch = e.target.value + ' logo';
      } else {
        finalSearch = e + '  Logo';
      }

      let logoImages = await axios
        .get(
          `https://api.bing.microsoft.com/v7.0/images/search?q=${finalSearch}`,
          {
            headers: {
              'Ocp-Apim-Subscription-Key': '33370f7ecda54460991c5ec01d911e3a',
            },
          }
        )
        .then((res) => {
          updateStateImages(res.data.value, false);
          return res.data.value;
        })
        .catch((err) => console.log('Error:', err));
      return logoImages;
    }
  };
  const handleBrandingClick = () => {
    setBrandingCategory(true);
    setConfigurationCategory(false);
    setManagementCategory(false);
  };

  const handleConfigurationClick = () => {
    setBrandingCategory(false);
    setConfigurationCategory(true);
    setManagementCategory(false);
  };

  const handleManagementClick = () => {
    setBrandingCategory(false);
    setConfigurationCategory(false);
    setManagementCategory(true);
  };

  const menuChange = async (category) => {
    console.log('CATEGORY: ', category);
  };

  const urlInputRef = useRef();

  const searchURL = () => {
    console.log('test');
    initalize(urlInputRef.current);
  };

  return (
    <div id="brandSettingsContainer">
      <div id="internal">
        <div id="search-bar">
          <input
            type="text"
            id="brandSettingURLInput"
            placeholder="Website URL"
            autoComplete="off"
            onKeyDown={(e) => initalize(e)}
            value={websiteUrl}
            onChange={(e) => setWebsiteURL(e.target.value)}
            ref={urlInputRef}
          />
          <button onClick={searchURL} id="search-button">
            Search
          </button>
        </div>
        <div id="menu-bar">
          <div
            onClick={handleBrandingClick}
            className={`settings-menu-bar ${brandingCategory ? 'active' : ''}`}
          >
            Branding
          </div>
          <div
            onClick={handleConfigurationClick}
            className={`settings-menu-bar ${
              configurationCategory ? 'active' : ''
            }`}
          >
            Configuration
          </div>
          {/* <div onClick={handleManagementClick}>Management</div> */}
        </div>
        {configurationCategory && (
          <AppContext.Provider
            value={{
              selectedSocial,
              setSelectedSocial,
              selectedMFA,
              setSelectedMFA,
              selectedAuthenticationProfile,
              setSelectedAuthenticationProfile,
            }}
          >
            <div id="card-container" className="swing-in-top-fwd">
              <SettingsCards> </SettingsCards>
              {/* <div id="update-button" onClick={updateSettings}> <button> Update Preview </button> </div> */}

              <div id="hello">
                <div> Enterprise Connection </div>
                <div id="displayAsButtonInnerDiv">
                  <div className="toggleDiv">
                    <p>Display as a Button</p>
                    <label className="switch">
                      <input
                        type="checkbox"
                        value={displayAsButtonEnabled}
                        checked={displayAsButtonEnabled}
                        onChange={(e) =>
                          setDisplayAsButtonEnabled(e.target.checked)
                        }
                        name="displayAsButton"
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                  <div className="toggleDiv">
                    <p>Enabled</p>
                    <label className="switch">
                      <input
                        type="checkbox"
                        value={entConnectionEnabled}
                        checked={entConnectionEnabled}
                        onChange={(e) =>
                          setEntConnectionEnabled(e.target.checked)
                        }
                        name="displayAsButton"
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div id="hello">
                <div> Organizational Prompt </div>
                <div>
                  <label className="switch">
                    <input
                      type="checkbox"
                      value={organizationEnabled}
                      checked={organizationEnabled}
                      onChange={(e) => setOrganizationEnabled(e.target.checked)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>

              <button id="update-preview" onClick={updateSettings}>
                Update Preview
              </button>
            </div>
          </AppContext.Provider>
        )}

        {brandingCategory && (
          <div className="swing-in-top-fwd">
            <div id="background-container">
              <div id="brandSettingInputContainer">
                {/* <label for="brandSetttingInput"> UL Background Image </label> */}
                <input
                  type="text"
                  id="brandSettingInput"
                  placeholder="Background Image Search"
                  value={backgroundSearch}
                  onChange={(e) => {
                    setBackgroundSearch(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    searchCustomBackgroundImages(e);
                  }}
                  autoComplete="off"
                />
              </div>
              <ImageSlider images={imagesUrl} type="background" />
              {/* <input
            type="text"
            id="brandSettingInput"
            placeholder="Background Image URL..."
          /> */}
            </div>

            <div id="spacer"></div>
            <div id="logo-container">
              <input
                type="text"
                id="brandSettingInput"
                placeholder="Logo Search"
                value={logoSearch}
                onChange={(e) => {
                  setLogoSearch(e.target.value);
                }}
                onKeyDown={(e) => {
                  searchLogoImages(e);
                }}
              />

              <ImageSlider images={logosUrl} type="logo" />
              {/* <input
            type="text"
            id="brandSettingInput"
            placeholder="Update Logo URL..."
          /> */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default BrandSettings;
