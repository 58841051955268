import React, { useContext, useEffect } from 'react';
import './landing.css';
import SessionController from '../../controllers/session';

function Landing(props) {
  let menu_element = document.querySelector('#styled-burger');
  if (menu_element) {
    menu_element.style.animation = 'pulsate-bck 0.5s ease-in-out infinite both';
    menu_element.style.opacity = 1;
  }
  useEffect(() => {
    (async () => {
      if (SessionController.hasError()) {
        alert(
          'Modern Toolkit V2 has not been added to your tenant. Please navigate to the demo platform and add it!'
        );
        window.location.href = '/tenant';
      } else {
        SessionController.setCookiesFromUrlParams();
        let sessionExists = await SessionController.checkSession();
      }
    })();
    //https://docs.google.com/document/d/1n0cTaGUKZPQpgoC6OCpwdAKHmn2UIdPHOch4pj6CEsM/edit
  }, []);

  return (
    <div className="scale-in-center">
      <div className="scale-up-center">
        <div className="container">
          <div id="imageDiv">
            <img id="logo" src="./Toolkit_Icon.png" width="500" height="300" />
          </div>

          <div id="toolTitle">
            {' '}
            <span id="toolTitleSpan"> Modern Toolkit </span>
          </div>
          <div id="attribution">
            {' '}
            Nithin Moorthy, Mark Vong, and Jeffrey Ciferno{' '}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Landing;
